.gaming-content{
    display: flex;
    background-color: #FAC496;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
    padding: 20px;
}
.gaming-header{
    font-size: calc(20px + 2vmin);
    color:#620002;
    font-weight: bold;
}
.gaming-sub-header{
    font-size: calc(15px + 1vmin);
    color:#620002;
    font-weight: bold;
}
.gaming-text{
    font-size: calc(10px + 1vmin);
    color: #620002;
}