.metahuman-content{
    display: flex;
    background-color: #FFF2CC;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 20vh;
    padding: 20px;
}
.metahuman-header{
    font-size: calc(20px + 2vmin);
    color:#E16F38;
    font-weight: bold;
}
.metahuman-text{
    font-size: calc(10px + 1vmin);
    color:#E16F38;
    padding:20px;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }