.footer-content{
    display: flex;
    background-color: #FFF2CC;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 2vh;
    width:100%;
    padding: 20px;
}
.footer-text{
    font-size: calc(10px + 1vmin);
    color:#E16F38;
}