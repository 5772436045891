.contact-content{
    display: flex;
    background-color: #FFF2CC;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 20vh;
    padding: 20px;
}
.contact-header{
    font-size: calc(20px + 2vmin);
    color:#E16F38;
    font-weight: bold;
}
.contact-text{
    font-size: calc(10px + 1vmin);
    color:#E16F38;
    padding:20px;
}