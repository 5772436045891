.main {
    text-align: center;
  
  }
  
  .main-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  
  .main-header {
    background-color: #FFF2CC;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    height: auto;
    padding-bottom: 50px;

  }
  .main-header-text {
    color:#E16F38;
  }
  
  .main-text {
    font-size: calc(6px + 2vmin);
    color:#E16F38;
  }

  .main-content{
    display: flex;
    background-color: #FAC496;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.logo-box {
    display: grid;
    height: 100%;
}
.logo-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}